
export default class AndMoreList
{
    static join(items, maxItems) 
    {
        if (!items.length) {
            return '';
        }

        if (items.length === 1) {
            return items[0];
        }

        if (items.length > maxItems + 2) {
            return items.slice(0, maxItems).join(', ') + ' and ' + (items.length - maxItems) + ' more';
        }

        return items.slice(0, items.length - 1).join(', ') + ' and ' + items[items.length - 1];
    }
}