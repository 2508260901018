export default class StickyFormButtons 
{

    static instance = null;

    static load() {

        const element = document.querySelector('.button-bar');

        if (element) {
            this.instance = new this(element);
        }

    }

    constructor(buttonBarElement) {
        this.buttonBarElement = buttonBarElement;
        this.footerElement = document.querySelector('footer');
        this.buttonBarElement = document.querySelector('.button-bar');
        this.contentElement = document.querySelector('main');


        this.contentElement.addEventListener('scroll', () => {
            this.tick();
        });

        window.addEventListener('resize', () => {
            this.tick();
        }); 

        this.tick();
    }
    
    tick() {

        const footerHeight = this.footerElement.offsetHeight;

        
        if (this.contentElement.scrollHeight > this.contentElement.offsetHeight + this.contentElement.scrollTop + 150) {
            this.buttonBarElement.style.bottom = footerHeight + 'px';
            this.buttonBarElement.classList.add('sticky');

            return;
        }

        this.contentElement.style.paddingBottom = 'auto';
        this.buttonBarElement.classList.remove('sticky');

    }

}
