export default class Metadata
{
    static get(metaField, defaultValue) 
    {

        if (typeof defaultValue === 'undefined') {
            defaultValue = null;
        }

        if (this.getFromMetaTag(metaField)) {
            return this.getFromMetaTag(metaField);
        }

        if (this.getFromInputTag(metaField)) {
            return this.getFromInputTag(metaField);
        }

        return defaultValue;

    }
    

    static getFromMetaTag(name) 
    {
        return this.getFromTag('meta', name, 'content');
    }

    static getFromInputTag(name) 
    {
        return this.getFromTag('input', name, 'value');
    }

    static getFromTag(tag, name, attribute) 
    {
        const query = tag + '[name="' + name + '"]';
        const element = document.querySelector(query);

        if (!element) {
            return null;
        }

        return element.getAttribute(attribute);
    }

    
}