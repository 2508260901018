const px = (x) => {
    return Math.round(x) + 'px';
};

const createElement = (tagName, properties) => {
    const element = document.createElement(tagName);

    if (properties) {
        if (properties.classes) {
            properties.classes.forEach(className => {
                element.classList.add(className);
            });
        }

        if (properties.children) {
            properties.children.forEach(childElement => {
                element.appendChild(childElement);
            });
        }
    }


    /** @todo children */
    return element;
};

export default class ToolTipText 
{

    static load() 
    {
        this.timeout = null;
        this.tttElement = null;

        this.hideElement();

        window.addEventListener('mouseover', (e) => {
            const target = this.getTargetByAttribute('data-tooltip', e.target);

            if (target) {
                this.showForTarget(target);
            }
        });

        window.addEventListener('mouseout', (e) => {
            const target = this.getTargetByAttribute('data-tooltip', e.target);

            if (target) {
                this.hideElement();
            }
        });

    }
    
    static getTargetByAttribute(attribute, target) 
    {
        if (target.hasAttribute(attribute)) { return target; }
        return target.closest('[' + attribute + ']');
    }

    static showForTarget(target) 
    {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            const tttElement = this.getElement();

            if (target.hasAttribute('title')) {
                target.setAttribute('data-title', target.getAttribute('title'));
                target.removeAttribute('title');
            }

            tttElement.style.visibility = 'none';
            tttElement.classList.remove('d-none');

            tttElement.querySelector('.text').innerText = target.getAttribute('data-title');


            const targetRect = target.getBoundingClientRect();
            const tttRect = tttElement.getBoundingClientRect();
            

            tttElement.style.left = px(targetRect.left + (targetRect.width - tttRect.width) / 2);
            tttElement.style.top = px(targetRect.top - tttRect.height);
            tttElement.style.visibility = 'visible';
        }, 250);

    }

    static hideElement() 
    {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (this.tttElement) {
            this.tttElement.classList.add('d-none');
        }
    }

    static getElement() {
        if (this.tttElement) {
            return this.tttElement;
        }

        const element = createElement('div', {
            classes: ['tooltip-element'],

            children: [
                createElement('div', {
                    classes: [
                        'arrow'
                    ]
                }),
                createElement('div', {
                    classes: [
                        'text'
                    ]
                })
            ]
        });
        
        
        document.body.appendChild(element);
    
        return this.tttElement = element;
    }

}