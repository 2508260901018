export default class Controller 
{

    static load() 
    {

    }

    static getRouteName() {
        const el = document.querySelector('meta[name="route"]');

        if (el) {
            return el.getAttribute('content');
        }
        return null;
    }

    /**
     * When on a route, defined by routeNames, run the controller code 
     * 
     * @param {@} routeNames 
     * @param {*} callback 
     */
    static when(routeNames, callback) 
    {
        const currentRoute = this.getRouteName();
        let i = routeNames.length;
        
        if (routeNames.includes(currentRoute)) {
            return callback();
        }

    }

}