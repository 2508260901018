import AndMoreList from "../../../components/AndMoreList";
import Metadata from "../../../helpers/Metadata";
import LoaderElementHtml from "../../../components/Loader.template?raw";

export default class AudienceSelectionRenderer
{

    constructor(audienceSelectionLoader) {
        this.audienceSelectionLoader = audienceSelectionLoader;

        this.tableElement = document.querySelector('#audienceTable');
        this.tableBodyElement = this.tableElement.querySelector('tbody');
        this.addContainerElement = this.tableElement.querySelector('[data-action="add"]').closest('tr');

        this.translations = {
            preview: Metadata.get('translation.preview'),
            edit: Metadata.get('translation.edit'),
            delete: Metadata.get('translation.delete'),
        }

    }

    render() {
        const data = this.audienceSelectionLoader.data;

        // delete all rows
        this.clearRows();

        // foreman is the only mode that allows for more than one 'items' in the dataset
        if (data.getItems().length > 0 && data.getMode() !== 'foreman') {
            this.addContainerElement.classList.add('d-none');
        } else {
            this.addContainerElement.classList.remove('d-none');
        }

        this.toggleTotalPreview(data.getItems().length > 0);

        // render a row per item
        data.getItems().forEach((item, rowIndex) => {

            const cells = [];
            let content = '';

            if (data.getMode() === 'foreman') {
                content = AndMoreList.join(Object.keys(item).map(key => {
                    return key + '=' + item[key];
                }));
            }
            else {
                content = AndMoreList.join(item, 5);
            }

            const stats = data.getStatRow(rowIndex);

            cells.push({
                content
            });

            cells.push({
                content: stats.teams,
            });

            cells.push({
                content: stats.servers,
            });

            cells.push({
                content: stats.recipients,
            });

            const rowElement = document.createElement('tr');

            cells.forEach((cell, cellIndex) => {
                const cellElement = document.createElement('td');
                const spanElement = document.createElement('span');

                // add SPAN to TD and TD to TR
                cellElement.appendChild(spanElement); rowElement.appendChild(cellElement);

                if (cellIndex === 0) {
                    // first cell, add edit and remove buttons

                    const previewElement = document.createElement('a');
                    const deleteElement = document.createElement('a');
                    const editElement = document.createElement('a');

                    previewElement.innerText = this.translations.preview;
                    previewElement.classList.add('float-end');
                    previewElement.classList.add('ms-2');
                    previewElement.setAttribute('href', '#');
                    previewElement.setAttribute('data-rowindex', rowIndex);
                    previewElement.setAttribute('data-action', 'preview');

                    deleteElement.innerText = this.translations.delete;
                    deleteElement.classList.add('float-end');
                    deleteElement.classList.add('ms-2');
                    deleteElement.setAttribute('href', '#');
                    deleteElement.setAttribute('data-rowindex', rowIndex);
                    deleteElement.setAttribute('data-action', 'delete');

                    editElement.innerText = this.translations.edit;
                    editElement.classList.add('float-end');
                    editElement.classList.add('ms-2');
                    editElement.setAttribute('href', '#');
                    editElement.setAttribute('data-rowindex', rowIndex);
                    editElement.setAttribute('data-action', 'edit');

                    cellElement.appendChild(deleteElement);cellElement.appendChild(editElement);cellElement.appendChild(previewElement);
                }
                else {
                    cellElement.classList.add('text-end');
                }

                if (this.audienceSelectionLoader.loading && cellIndex > 0) {
                    spanElement.innerHTML = LoaderElementHtml;
                    return;
                }

                spanElement.innerText = cell.content;

            });

            this.tableBodyElement.appendChild(rowElement);

        });


        this.tableElement.querySelectorAll('tr[data-total-mode]').forEach(totalRow => {

            const rowMode = totalRow.getAttribute('data-total-mode');

            const totals = data.getStatRow(rowMode);

            if (rowMode === 'totals' || totals.teams + totals.servers + totals.recipients > 0) {
                totalRow.classList.remove('d-none');
            }
            else {
                totalRow.classList.add('d-none');
            }

            totalRow.querySelectorAll('th').forEach((cell, cellIndex) => {
                if (cellIndex > 0) {        
    
                    if (cellIndex === 1) {
                        cell.innerText = totals.teams;
                    }
    
                    if (cellIndex === 2) {
                        cell.innerText = totals.servers;
                    }
    
                    if (cellIndex === 3) {
                        cell.innerText = totals.recipients;
                    }
                }
    
            });

        });

    }

    clearRows() {
        this.tableBodyElement.querySelectorAll('tr').forEach(rowElement => {
            rowElement.remove();
        });
    }

    toggleTotalPreview(state) {
        const element = this.tableElement.querySelector('tfoot a[data-action="preview"]');

        if (!element) { return ; }

        if (state) {
            element.classList.remove('d-none');
            return;
        } 
        
        element.classList.add('d-none');

    }
}