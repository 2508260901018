
export default class AudienceSelection
{

    constructor() 
    {
        this.mode = null;
        this.stats = null;
        this.items = [];
    }

    setStats(stats) {
        this.stats = stats;
    }

    getStatRow(rowIndex) {
        
        if (String(rowIndex).match(/[^0-9]/) && this.stats) {
            return this.stats[rowIndex];    
        }

        if (this.stats && this.stats.items && this.stats.items[rowIndex]) {
            return this.stats.items[rowIndex];
        }

        return {
            teams: 0,
            servers: 0,
            recipients: 0
        };
    }

    getStats() {
        return this.stats;
    }

    setMode(mode) 
    {
        if (!['foreman', 'servers', 'teams'].includes(mode)) {
            mode = null;
        }
        this.mode = mode;
    }

    setItems(items) 
    {
        if (items && items.length && items.pop) {
            // is an array
            this.items = items;
            return;
        }
        this.items = [];
    }

    getMode() 
    {
        return this.mode;
    }

    add(item) 
    {
        this.items.push(item);
    }

    update(index, item) 
    {
        this.items[index] = item;
    }

    remove(index) 
    {
        this.items.splice(index, 1);
        if (this.items.length === 0) {
            // removed all items of the list, so switch to default mode.
            this.setMode();
        }
    }

    get(index) 
    {
        return this.items[index];
    }

    getItems() 
    {
        return this.items;
    }


    fromJson(json) 
    {
        let input = null;

        if (!json) {
            this.setItems([]);
            this.setMode('');
            return;
        }

        try {
            input = JSON.parse(json);
        }
        catch(e) {
            console.error('Bad json!');
            console.error(json);
            return;
        }

        this.setMode(input.mode);
        this.setItems(input.items);
    }

    toJson() {
        return JSON.stringify({
            mode: this.mode,
            items: this.items
        }, null, 2);
    }

}
