export default class ElementStampRegistry
{

    constructor(attribute, format) {
        this.pointer = 0;

        this.index = {};
        this.attribute = attribute;
        this.format = typeof format === 'undefined' ? this.generateFormat(attribute) : format;
    }

    generateFormat(elementAttribute) {

        if (elementAttribute.indexOf('data-') === 0) {
            // starts with data
            return elementAttribute.substring(5) + '-';
        }

        return elementAttribute + '-';

    }

    stamp(element) {

        if (element.hasAttribute(this.attribute) && element.getAttribute(this.attribute) !== '') {
            // element has been stamped and should be on our registry
            return element.getAttribute(this.attribute);
        }

        // element has not been stamped, give it a unique id
        this.pointer++;

        element.setAttribute(this.attribute, this.format + this.pointer);
        this.index[this.format + this.pointer] = element;
        
        return this.format + this.pointer;

    }

}