import Controller from './Controller';

export default class LoginController extends Controller
{

    static instances = [];
    static pointer = 1;

    static load()
    {

        this.when(['login', 'password.request'], () => {

            document.querySelectorAll('[data-image-rotation]').forEach(containerElement => {

                containerElement.style.position = 'relative';

                containerElement.querySelectorAll('img').forEach((element, index) => {

                    element.style.position = 'absolute';
                    element.style.left = 0;
                    element.style.top = 0;

                });

            });

            setInterval(() => this.tick(), 5000);

        });

    }
    
    static tick() {

        this.pointer++;

        const active = (this.pointer % 3);
        const next = ((1 + this.pointer) % 3);

        document.querySelectorAll('[data-image-rotation]').forEach(containerElement => {

            containerElement.querySelectorAll('img').forEach((element, index) => {

                if (index === active) {
                    element.classList.remove('fadeIn');

                    element.style.opacity = 1;
                    element.style.zIndex = 10;
    
                    return;
                }

                if (index === next) {
                    element.style.opacity = 0;
                    element.style.zIndex = 15;

                    element.classList.add('fadeIn');
                    return;
                }

                element.style.opacity = 0;
                element.classList.remove('fadeIn');

            });


        });

    }

}