import ModalLoader from "./ModalLoader";
import LoaderElementHtml from "./Loader.template?raw";

export default class Modal 
{

    constructor(element) {
        this.element = element;
        
        this.resolve = null;
        this.reject = null;
    }

    show() {
        ModalLoader.getInstance().pushStack(this);
    }

    hide() {
        ModalLoader.getInstance().popStack();
    }

    getElement() {
        return this.element;
    }

    setTitle(title) {
        this.getElement().querySelector('.modal-title').innerText = title;
        return this;
    }

    setSubmit(submit) {
        return this;
    }

    showLoader() {

        const divs = [];
        const classes = [
            'modal-dialog modal-xl',
            'modal-content',
            'modal-body',
        ];

        this.getElement().innerHTML = '';

        classes.forEach((classNames, index) => {
            const div = document.createElement('div');

            divs.push(div);

            classNames.split(/\s/g).forEach(className => {
                div.classList.add(className);
            });

            if (index === 0) {
                this.getElement().appendChild(div);
                return;
            }

            divs[index - 1].appendChild(div);
        });

        divs[2].innerHTML = LoaderElementHtml;

        divs[2].classList.add('m-3');
        divs[2].querySelector('svg').style.width = '40px';
        divs[2].querySelector('svg').style.height = '40px';

        this.show();
    }



    promise() {
        return new Promise((resolve, reject) => {

            this.resolve = resolve;
            this.reject = reject;

            this.show();
        });
    }
}