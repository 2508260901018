import Controller from "../../Controller";

export default class SendController extends Controller
{

    static load() 
    {
        // when on the Send route for mail campaigns run the following code
        this.when(['mail-campaign.message.send.index'], () => {
            this.showOrHideDates();
            this.changeSubmitLabel();

            window.addEventListener('input', (e) => {
                const target = e.target;
                
                if (target.getAttribute('name') === 'status') {

                    this.showOrHideDates(true);
                    this.changeSubmitLabel();

                }
            });
        });
    }

    /**
     * the "date" and "time" fields are only applicable if "schedule send" was selected.
     */
    static showOrHideDates(focus) 
    {
        let state = false;
        
        state = (document.querySelector('form[mail-campaign-send] input[name="status"]:checked')?.getAttribute('value') === 'scheduled');

        document.querySelectorAll('[data-schedule]').forEach(scheduleElement => {

            if (state) {
                scheduleElement.classList.add('d-block');
                scheduleElement.classList.remove('d-none');

                if (focus) {
                    const el = document.getElementById('sent_at_date');
                    if (el) {
                        el.focus();
                    }
                }
                return;
            }

            scheduleElement.classList.add('d-none');
            scheduleElement.classList.remove('d-block');

        });

    }

    static defaultLabel = null;
    static changeSubmitLabel()
    {
        let label = document.querySelector('form[mail-campaign-send] input[name="status"]:checked')?.getAttribute('data-submit-label');
        let submitElement = document.querySelector('form[mail-campaign-send] button[type="submit"]')

        if (this.defaultLabel === null) {
            this.defaultLabel = submitElement.innerText;
        }

        submitElement.innerText = label || this.defaultLabel;
    }

} 