import Controller from "../../Controller";

export default class MessageSendController extends Controller
{

    static load()
    {
        this.when(['mail-campaign.message.send.index'], () => {
            
            window.addEventListener('click', e => {

                if (e.target.hasAttribute('data-date-add')) {
                    e.preventDefault();
                    this.handleDateAdd(e.target);
                    return;
                }

                if (e.target.closest('[data-date-add]')) {
                    e.preventDefault();
                    this.handleDateAdd(e.target.closest('[data-date-add]'));
                    return;
                }

            });

        });

    }


    static handleDateAdd(element) 
    {

        const form = element.closest('form');
        const add = element.getAttribute('data-date-add');

        if (!form) {
            return;
        }

        // get current value
        const currentDate = this.getCurrentDate(form);


        if (add.indexOf('1d') !== -1) {
            if (add.indexOf('-') === 0) {
                currentDate.setDate(currentDate.getDate() - 1);
            }
            else {
                currentDate.setDate(currentDate.getDate() + 1);
            }
        }

        if (add.indexOf('15m') !== -1) {
            if (add.indexOf('-') === 0) {
                currentDate.setMinutes(currentDate.getMinutes() - 15);
            }
            else {
                currentDate.setMinutes(currentDate.getMinutes() + 15);
            }
        }
        
        
        this.setValues(form, currentDate);


    }

    static getCurrentDate(form) 
    {
        const date = form.querySelector('[type="date"]').value;
        const time = form.querySelector('[type="time"]').value;

        if (!date || !time) {
            return null;
        }

        const dateObject = new Date();

        dateObject.setDate(1);
        dateObject.setMonth(1);
        dateObject.setMilliseconds(0);
        dateObject.setSeconds(0);


        let tmp = date.split(/-/g);
        dateObject.setFullYear(tmp[0]);
        dateObject.setMonth(tmp[1] - 1);
        dateObject.setDate(tmp[2]);

        tmp = time.split(/:/g);
        dateObject.setHours(tmp[0]);
        dateObject.setMinutes(tmp[1]);

        return dateObject;

    }

    static setValues(form, date) 
    {

        const d = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(item => {
            item = String(item);
            if (item.length < 2) {
                return '0' + item;
            }
            return item;
        });

        form.querySelector('[type="date"]').value = d.join('-');

        const t = [date.getHours(), date.getMinutes()].map(item => {
            item = String(item);
            if (item.length < 2) {
                return '0' + item;
            }
            return item;
        });


        form.querySelector('[type="time"]').value = t.join(':');

    }

}