import CsrfToken from "../helpers/CsrfToken";
import ElementStampRegistry from "./ElementStampRegistry";

export default class InteractionLoader
{

    static instance;

    static getInstance() {
        if (!this.instance) {
            this.instance = new this;
        }
        return this.instance;
    }

    static load() {

        // build object
        this.getInstance();

    }

    constructor() {
    
        // any element that has a [data-confirm="msg?"] attribute triggers a confirmation on click
        this.bindConfirmEvents();

        // any form that has the [data-disable-on-submit] attribute disables submit buttons when submitting (this prevents double-submits)
        this.bindDisableOnSubmitEvents();

        // any element that has the [data-delete] attribute performs a HTTP DELETE statement on click
        this.bindDeleteEvents();

        // any table container that has the [data-searchable] attribute can be used to search through. Each row should have an the 
        // [data-searchable="JSON.stringify([])"] attribute (that is an array), that contains the fields that can be searched. 
        this.bindTableSearchEvents();
        this.bindTableSelectAllEvents();
        this.bindSelectAllEvents();
        this.bindClickableRows();
        this.bindClearableElement();
    }


    // @todo: no need to wrap this in a function
    getCsrfToken() {
        return CsrfToken.get();
    }


    bindConfirmEvents() 
    {

        window.addEventListener('submit', e => {

            if (e.target.hasAttribute('data-confirm')) {
                if (!window.confirm(e.target.getAttribute('data-confirm'))) {
                    e.preventDefault();
                    return false;
                }
            }

        });

        window.addEventListener('click', e => {

            if (e.target.hasAttribute('data-confirm')) {
                if (!window.confirm(e.target.getAttribute('data-confirm'))) {
                    e.preventDefault();
                    return false;
                }
            }
        });

    }

    bindDisableOnSubmitEvents() 
    {

        window.addEventListener('click', e => {
            if (!e.target) {
                return;
            }

            let target = null;

            if (e.target.hasAttribute('data-disable-on-click')) {
                target = e.target;
            }
            if (e.target.closest('[data-disable-on-click]')) {
                target = e.target.closest('[data-disable-on-click]');
            }

            if (target) {

                target.classList.add('disabled');

                setTimeout(() => {

                    if (target.closest('.sidebar')) {
                        // if this is a menu item, add a loading badge
                        if (!target.querySelector('a .badge-container')) {
                            const span = document.createElement('span');
                            span.classList.add('badge-container');

                            const span2 = document.createElement('span');
                            span2.classList.add('badge');
                            span2.classList.add('badge-icon');

                            target.querySelector('a').appendChild(span);
                            span.appendChild(span2);

                        }

                        const badgeSpan = target.querySelector('a .badge-container .badge');
                        badgeSpan.classList.add('badge-icon');
                        badgeSpan.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height"12" fill="currentColor" class="rotate bi bi-arrow-clockwise" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/></svg>';
                    }

                }, 250);
            }
        });

        window.addEventListener('submit', e => {

            if (e.target.hasAttribute('data-disable-on-submit')) {
                e.target.querySelectorAll('input[type=submit],button').forEach(button => {
                    button.setAttribute('disabled', true);
                });
            }

        });
    }

    bindDeleteEvents() 
    {
        window.addEventListener('click', e => {

            if (e.target.hasAttribute('data-delete')) {
                e.preventDefault();

                fetch(e.target.getAttribute('data-delete'), {
                    method: 'DELETE',
                    headers: { 
                        'x-csrf-token': this.getCsrfToken(),
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (e.target.hasAttribute('data-then')) {
                        document.location.href = e.target.getAttribute('data-then');
                        return;
                    }
                });
            }
        });

    }

    bindTableSearchEvents() {
        
        window.addEventListener('input', e => {
            const target = e._target || e.target;

            if (target.tagName === 'INPUT' && target.getAttribute('name') === 'filter') {
                if (target.closest('[data-table-search]')) {
                    SearchableTable.getInstance(target.closest('[data-table-search]')).search(target.value);


                    const table = target.closest('[data-table-search]').querySelector('table');
                    if (table) {
                        SelectAllInTable.getInstance(table).update();
                    }
                }
            }   

        });

    }

    bindTableSelectAllEvents() {

        window.addEventListener('click', e => {

            if (e.target.tagName === 'INPUT') {
                if (e.target.closest('[data-toggle-all]')) {
                    const table = e.target.closest('table');

                    if (!table) {
                        return;
                    }
                    
                    if (e.target.parentNode.tagName === 'TH') {
                        SelectAllInTable.getInstance(table).toggleAll(e.target.checked);
                        return;
                    }
                    
                    SelectAllInTable.getInstance(table).update();
                }
            }

        });
    }

    bindSelectAllEvents() {

        window.addEventListener('click', e => {
            const target = e._target || e.target;

            if (e.target.hasAttribute('data-select')) {
                const checked = (e.target.getAttribute('data-select') === 'all');
                e.preventDefault();

                const parent = target.closest('form,[data-select-all],body');
                if (!parent) {
                    return;
                }

                parent.querySelectorAll('input[type="checkbox"]').forEach(element => {
                    element.checked = checked;

                    return;
                    if (!checked) {
                        element.removeAttribute('checked');
                        return
                    }
                });

            }
        })

    }


    bindClickableRows() {
        window.addEventListener('click', e => {
            if (e.target.tagName === 'TD') {
                if (e.target.closest('[data-clickable-rows]')) {
                    e.preventDefault();
                    const tr = e.target.closest('tr');
                    
                    if (tr.querySelector('input[type="checkbox"]')) {
                        tr.querySelector('input[type="checkbox"]').checked = !tr.querySelector('input[type="checkbox"]').checked
                    }
                }
            }
        });
    }

    bindClearableElement() {

        document.querySelectorAll('[data-clearable]').forEach(element => {
            ClearableElement.getInstance(element);
        });

        window.addEventListener('click', e => {
            const target = e._target || e.target;
            const container = target.closest('[data-clearable]');

            if (container && target.tagName === 'A') {
                e.preventDefault();
                ClearableElement.getInstance(container).clear();
            }
        });

        window.addEventListener('input', e => {
            const target = e._target || e.target;
            const container = target.closest('[data-clearable]');

            if (container) {
                ClearableElement.getInstance(container).update();
            }
        });

    }

}

export class ClearableElement {

    static instances = {};
    static stampRegistry = new ElementStampRegistry('data-clearable');

    static getInstance(element) {
        const stamp = this.stampRegistry.stamp(element);
        if (!this.instances[stamp]) {
            this.instances[stamp] = new this(element);
        }
        return this.instances[stamp];
    }

    constructor(element) { 
        this.element = element;

        this.inputElement = element.querySelector('input');
        this.clearElement = element.querySelector('a');

        this.update();
    }

    clear(focus) {
        this.setValue('');
        if (focus !== false) {
            this.inputElement.focus();
        }
    }

    setValue(value) {
        this.inputElement.value = value;
        this.update();

        const e = new Event('input');
        e._target = this.inputElement;
        window.dispatchEvent(e);
    }

    update() {
        if (this.inputElement.value === '') {
            this.clearElement.classList.add('d-none');
            return;
        }

        this.clearElement.classList.remove('d-none');
    }

}

export class SelectAllInTable {

    static stampRegistry = new ElementStampRegistry('data-toggle-all');
    static instances = {}
    
    static getInstance(element) {
        const stamp = this.stampRegistry.stamp(element);

        if (!this.instances[stamp]) {
            this.instances[stamp] = new this(element);
        }
        return this.instances[stamp];
    }

    constructor(element) {
        this.element = element;
    }

    toggleAll(status) {
        this.element.querySelectorAll('tbody tr:not(.d-none) td input').forEach(element => {
            element.checked = status;
        });
    }

    update() {
        let all_checked = true;

        this.element.querySelectorAll('tbody tr:not(.d-none) td input').forEach(element => {
            if (!element.checked) {
                all_checked = false;
                return false;
            }
        });

        this.element.querySelector('th input').checked = all_checked;
    }


}

export class SearchableTable {

    static stampRegistry = new ElementStampRegistry('data-table-search');
    static instances = {};
    
    static getInstance(element) {
        const stamp = this.stampRegistry.stamp(element);

        if (!this.instances[stamp]) {
            this.instances[stamp] = new this(element);
        }
        return this.instances[stamp];
    }

    static resetAll() {
        Object.keys(this.instances).forEach(stamp => {
            this.instances[stamp].reset();
        });
    }

    constructor(element) {
        this.element = element;
        this.rows = [];

        this.indexRows();
    }

    reset() {
        ClearableElement.getInstance(this.element.querySelector('[data-clearable]')).clear();
    }

    indexRows() {
        this.element.querySelectorAll('tbody tr').forEach(element => {

            const json = JSON.parse(element.getAttribute('data-searchable'));

            if (!json.map) {
                return;
            }


            const filter = json
                .map(filterValue => {
                    return String(filterValue).replace(/^\s+|\s+$/g, '').toUpperCase();
                });

            this.rows.push({
                element,
                filter
            });
        });
    }

    search(text) {
        // trim text and convert to string
        text = String(text).replace(/^\s+|\s+$/g, '').toUpperCase();

        this.rows.forEach(row => {

            let visible = false;

            if (text === '') {
                visible = true;
            }
            else {
                row.filter.forEach(filterValue => {
                    if (filterValue.indexOf(text) !== -1) {
                        visible = true;
                        return false;
                    }
                });
            }


            if (!visible) {
                row.element.classList.add('d-none');
                return;
            }

            row.element.classList.remove('d-none');
        });

        return this;
    }
}