import Modal from "../../../components/Modal";

export default class AudienceModal extends Modal
{
    constructor(element) 
    {
        super(element);

        this.mode = null;

        window.addEventListener('submit', e => {
            const target = e._target || e.target;

            if (target.closest('#audience-modal')) {
                e.preventDefault();

                this.saveAndClose();
            }
        });

        window.addEventListener('click', e => {
            const target = e._target || e.target;

            if (target.getAttribute('name') === 'audience-selector') {
                this.toggleAudienceSelector(target.value);
            }
        });
    }

    setMode(mode, disableOtherModes)
    {
        this.element.querySelectorAll('[name="audience-selector"]').forEach(radioElement => {
            radioElement.checked = (radioElement.value === mode);
            radioElement.disabled = disableOtherModes && (radioElement.value !== mode);
        });

        this.toggleAudienceSelector(mode);

        return this;
    }

    toggleAudienceSelector(mode) {
        this.mode = mode;

        this.element.querySelectorAll('[data-toggle-audience]').forEach(audienceDivElement => {
            if (audienceDivElement.getAttribute('data-toggle-audience') === mode) {
                audienceDivElement.classList.remove('d-none');
                return;
            }

            audienceDivElement.classList.add('d-none');
        });
    }

    load(data) {
        this.element.querySelectorAll('input[type=checkbox],select').forEach(element => {

            // check if element is a select
            if (element.tagName === 'SELECT') {
                const name = element.getAttribute('name');
                element.value = null;

                // if we have a value, set it to the select box. If not, we're probably not editing an audience
                if (this.mode === 'foreman' && data) {
                    element.value = data[name];
                }
                return;
            }

            // it must be a checkbox
            if (this.mode === 'foreman') {
                // in foreman selection we have no checkboxes, so disable all of them
                element.checked = false;
                return;
            }
            
            const value = element.getAttribute('value');
            // if we have data and data[] includes the value of our checkbox, select it
            element.checked = data && data.includes(value);

        });
        return this;
    }
    
    showError() {
        this.getElement().querySelector('[data-error]').classList.add('d-block');
        this.getElement().querySelector('[data-error]').classList.remove('d-none');
    }

    hideError() {
        this.getElement().querySelector('[data-error]').classList.remove('d-block');
        this.getElement().querySelector('[data-error]').classList.add('d-none');
    }

    saveAndClose() {
        let data = [];

        this.hideError();

        if (this.mode === 'foreman') {
            data = {};

            this.element.querySelectorAll('[data-toggle-audience="foreman"] select').forEach(item => {
                if (item.value) {
                    data[item.getAttribute('name')] = item.value;
                }
            });

            if (Object.keys(data).length === 0) {
                this.showError();
                return;
            }

        }
        else {
            data = [];
            this.element.querySelectorAll('[data-toggle-audience="' + this.mode + '"] td input[type="checkbox"]').forEach(item => {
                if (item.checked) {
                    data.push(item.value);
                }
            });

            if (data.length === 0) {
                this.showError();
                return;
            }
        }

        this.resolve(data, this.mode);
        this.resolve = this.reject = null;
        this.hide();
    }

}