import Modal from "../../../components/Modal";
import xIconHtml from "../../../components/icons/x-lg.template?raw"

export default class MetadataModal extends Modal
{

    constructor(element) 
    {
        super(element);

        this.illegalFieldNames = [];
        this.variables = [];
        this.tableElement = element.querySelector('table');
        this.tableBodyElement = element.querySelector('table tbody');

        this.errors = {};

        window.addEventListener('submit', e => {
            if (e.target.classList.contains('modal-dialog')) {
                e.preventDefault();
                this.errors = {};

                this.variables.forEach((v, rowIndex) => {
                    if (v.name === '') {
                        if (!this.errors[rowIndex]) {
                            this.errors[rowIndex] = {};
                        }
                        this.errors[rowIndex].name = 'This field is required';
                        return;
                    }
                    if (v.name.match(/[^A-Za-z0-9_]/g)) {
                        if (!this.errors[rowIndex]) {
                            this.errors[rowIndex] = {};
                        }
                        this.errors[rowIndex].name = 'Only A-Z, a-z, 0-9 and underscores are allowed';
                        return;
                    }
                    if (this.illegalFieldNames.includes(v.name)) {
                        if (!this.errors[rowIndex]) {
                            this.errors[rowIndex] = {};
                        }
                        this.errors[rowIndex].name = 'Variable exists already';
                        return;
                    }
                });

                if (Object.keys(this.errors).length > 0) {
                    this.renderVariables();
                    return;
                }

                this.resolve(this.variables);
                this.hide();
            }

        });

        window.addEventListener('input', e => {
            if (e.target.hasAttribute('data-type')) {
                
                const rowIndex = e.target.getAttribute('data-rowindex') * 1;
                const type = e.target.getAttribute('data-type');
                
                this.variables[rowIndex][type] = e.target.value;
            }
        });

        window.addEventListener('click', e => {

            let target = e._target || e.target;

            if (target.closest && target.closest('[data-action]')) {
                target = target.closest('[data-action]');
            }

            if (target.hasAttribute('data-action')) {
                e.preventDefault();

                const action = target.getAttribute('data-action');
                const rowIndex = target.getAttribute('data-rowindex');

                if (action === 'add') {
                    this.variables.push({ name: '', default: '', type: 'text' });
                    this.renderVariables();

                    this.element.querySelector('tr:last-child input:first-child').focus();
                    
                    return;
                }

                if (action === 'delete') {
                    this.errors = {};
                    this.variables.splice(rowIndex * 1, 1);     
                    this.renderVariables();

                    return;
                }

            }
        });

    }

    setVariables(variables) {
        this.variables = variables; 
    }

    setIllegalFieldNames(illegalFieldNames) {
        this.illegalFieldNames = illegalFieldNames;
    }

    show() {
        this.renderVariables();
        super.show();
    }

    renderVariables() {

        // remove all tr's on the table
        this.tableBodyElement.querySelectorAll('tr').forEach(element => { element.remove(); });

        if (!this.variables) {
            return;
        }
        // and add a row per variable
        this.variables.forEach((variable, rowIndex) => {
            const row = document.createElement('tr');

            [0, 1, 2, 3].forEach(cellIndex => {

                const cell = document.createElement('td');

                cell.classList.add('align-middle');
                cell.classList.add('text-center');
                row.appendChild(cell);

                // delete button
                if (cellIndex === 3) {

                    const a = document.createElement('a');
                    cell.appendChild(a);

                    a.innerHTML = xIconHtml;
                    a.setAttribute('href', '');
                    a.setAttribute('data-action', 'delete');
                    a.setAttribute('data-rowindex', rowIndex);

                    return;
                }

                // dropdown for type
                if (cellIndex === 0) {
                    const select = document.createElement('select');
                    cell.appendChild(select);

                    ['text', 'date'].forEach(item => {

                        const option = document.createElement('option');
                        option.innerText = item.substring(0, 1).toUpperCase() + item.substring(1).toLowerCase();
                        option.setAttribute('value', item.toLowerCase());
                        select.appendChild(option);
                        
                    });

                    select.value = variable.type || 'text';
                    select.setAttribute('data-rowindex', rowIndex);
                    select.setAttribute('data-type', 'type');
    
                    select.classList.add('form-select');
                    return;
                }

                const input = document.createElement('input');
                
                let type = 'default';
                if (cellIndex === 1) {
                    type = 'name';
                }

                input.value = variable[type];
                input.setAttribute('data-rowindex', rowIndex);
                input.setAttribute('data-type', type);
                
                input.classList.add('form-control');

                cell.appendChild(input);

                if (this.errors[rowIndex] && this.errors[rowIndex][type]) {
                    input.classList.add('is-invalid');    

                    const errDiv = document.createElement('div');
                    errDiv.classList.add('invalid-feedback');
                    errDiv.innerText = this.errors[rowIndex][type];
                    cell.appendChild(errDiv);

                }

            });

            this.tableBodyElement.appendChild(row);

        });

    }

}
