import Modal from "./Modal";

export default class ModalLoader
{
    static instance;

    static registry = [];
    
    static getInstance() {

        if (!this.instance) {
            this.instance = new this;
        }
        return this.instance;

    }

    static load() {
        this.getInstance();
    }

    constructor() {
        this.animationSpeed = 250;
        this.modals = [];
        this.modalsById = {};

        this.visibleModalStack = [];

        // find backdrop
        this.modalContainerElement = this.getModalContainerElement();

        // find all modals in view and add them
        document.querySelectorAll('.modal').forEach(element => {
            this.registerModal(element);
        });

        this.bindEvents();
    }

    bindEvents() {
        
        window.addEventListener('keyup', e => {
            if (e.key === 'Escape') {
                e.preventDefault();
                this.popStack();
            }
        });

        window.addEventListener('mousedown', e => {
            if (e.target.classList.contains('modal')) {
                e.preventDefault();
                this.popStack();
            }
        });

        window.addEventListener('click', e => {

            if (e.target.hasAttribute('data-modal')) {
                e.preventDefault();
                this.get(e.target.getAttribute('data-modal')).show();
                return;
            }

            if (e.target.hasAttribute('data-open-in-modal')) {
                e.preventDefault();
                
                const url = e.target.getAttribute('href');

                if (!document.querySelector('#ajax-modal')) {
                    const modal = document.createElement('div');
                    modal.setAttribute('id', 'ajax-modal');
                    ['modal', 'fade', 'show'].forEach(className => { modal.classList.add(className); });
                    this.getModalContainerElement().appendChild(modal);
                    this.registerModal(modal);
                }

                fetch(url, {
                    method: 'GET',
                })
                .then(async response => {
                    if (await response.status !== 200) {
                        this.get('ajax-modal').hide();
                        return;
                    }

                    document.querySelector('#ajax-modal').innerHTML = await response.text();
                    this.get('ajax-modal').show();
                });

                return;
            }

            if (e.target.getAttribute('data-bs-dismiss') === 'modal') {
                e.preventDefault();

                this.popStack();
            }
        });
    }

    get(id) {
        if (id in this.modalsById) {
            return this.modalsById[id];
        }
        return null;
    }

    registerModal(element) {
        const modal = new Modal(element);

        if (element.getAttribute('id')) {
            this.modalsById[element.getAttribute('id')] = modal;
        }

        this.modals.push(modal);
        return modal;
    }

    pushStack(modal) {
        if (this.visibleModalStack.length === 0) {
            
            // first modal that opens, animate backdrop
            this.getModalContainerElement().classList.add('show');

            this.getBackdropElement().classList.add('show');
            this.getBackdropElement().classList.add('fade-in');

            setTimeout(() => {
                modal.getElement().classList.add('show');
                modal.getElement().classList.add('fade-in');
            }, this.animationSpeed);

        }
        else {
            modal.getElement().style.zIndex = (10 + 10 * this.visibleModalStack.length);
            modal.getElement().classList.add('d-block');
            modal.getElement().classList.remove('d-none');
        }
        this.visibleModalStack.push(modal);
    }

    popStack() {
        const modal = this.visibleModalStack.pop();
        if (!modal) {
            // stack was empty;
            return;
        }
        modal.getElement().classList.remove('fade-in');

        if (modal.resolve) { 
            modal.resolve();
            modal.reject = modal.resolve = null;
        }

        setTimeout(() => {
            
            modal.getElement().classList.remove('show');

            if (this.visibleModalStack.length === 0) {

                this.getBackdropElement().classList.remove('fade-in');

                setTimeout(() => {
                    this.getBackdropElement().classList.remove('show');
                    this.getModalContainerElement().classList.remove('show');
                }, this.animationSpeed);
            }
        }, this.animationSpeed);

    }

    getBackdropElement() {
        return this.getModalContainerElement().querySelector('.backdrop');
    }

    getModalContainerElement() {
        const element = document.getElementById('modal-with-backdrop');
        // @todo: if element does not exist, create it?
        return element;
    }

}
