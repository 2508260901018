import ModalLoader from "../../../components/ModalLoader";
import CsrfToken from "../../../helpers/CsrfToken";
import Metadata from "../../../helpers/Metadata";
import AudienceModal from "./AudienceModal";
import AudienceSelection from "./AudienceSelection";
import AudienceSelectionRenderer from "./AudienceSelectionRenderer";

export default class AudienceSelectionLoader    
{
    static instance = null;

    static load() 
    {
        const element = document.querySelector('#audience_json');

        if (element) {
            // element exists, keep loading
            this.instance = new this(element);
        }

    }

    constructor(element) 
    {
        this.element = element;
        this.renderer = new AudienceSelectionRenderer(this);

        this.modal = new AudienceModal(document.querySelector('#audience-modal'));

        this.data = new AudienceSelection();
        this.data.fromJson(this.element.value);

        this.loading = false;

        this.render();

        window.addEventListener('click', e => {
            const target = e._target || e.target;

            if (target.hasAttribute('data-action')) {
                e.preventDefault();

                const rowIndex = target.getAttribute('data-rowindex');

                if (target.getAttribute('data-action') === 'add') {
                    this.modal
                        .setMode(this.data.getMode(), this.data.getItems().length > 0)
                        .load(null)
                        .promise()
                        .then(item => {
                            if (!item) { return; }
                            this.data.add(item);
                            this.data.setMode(this.modal.mode);

                            this.render();
                            this.saveAudience();

                            this.collectStatistics().then(() => { this.render(); this.saveAudience(); });
                        });

                    return;
                }

                if (target.getAttribute('data-action') === 'edit') {
                    this.modal
                        .setMode(this.data.getMode(), true)
                        .load(this.data.get(rowIndex * 1))
                        .promise()
                        .then(item => {
                            if (!item) { return; }

                            this.data.update(rowIndex * 1, item);
                            this.data.setMode(this.modal.mode);
                            this.render();

                            this.saveAudience();

                            this.collectStatistics().then(() => { this.render(); this.saveAudience(); });
                        });

                    return;
                }

                if (target.getAttribute('data-action') === 'delete') {
                    this.data.remove(rowIndex * 1);
                    this.render();

                    this.saveAudience();
                    
                    this.collectStatistics().then(() => { this.render(); this.saveAudience(); });
                    return;
                }


                if (target.getAttribute('data-action') === 'preview') {

                    let url = Metadata.get('debug-url');
                    const csrfToken = CsrfToken.get();

                    if (url.indexOf('?') === -1) {
                        url += '?';
                    } else {
                        url += '&';
                    }

                    url += 'rowIndex=' + rowIndex;

                    const modal = ModalLoader.getInstance().get('preview-modal');

                    modal.showLoader();


                    fetch(url, {
                        method: 'POST',
                        headers: { 
                            'x-csrf-token': csrfToken,
                            'Content-Type': 'application/json',
                        },
                        body: this.data.toJson()
                    })
                    .then(async (response) => {
                        modal.getElement().innerHTML = await response.text();
                    });


                    return;

                }

            }
        });

        this.collectStatistics();
    }

    saveAudience() {
        this.element.value = this.data.toJson();
    }

    render() {
        this.renderer.render();
    }

    collectStatistics() {

        return new Promise((resolve, reject) => {

            const csrfToken = CsrfToken.get();

            this.loading = true;
            this.render();
            
            fetch(Metadata.get('stat-url'), {
                method: 'POST',
                headers: { 
                    'x-csrf-token': csrfToken,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: this.data.toJson()
            })
            .then(async (response) => {
                this.data.setStats(await response.json());

                this.saveAudience();
                this.loading = false;
                this.render();
                resolve();

            });

        });

    }

}


